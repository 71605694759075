import React, {Component, useEffect, useState, memo, createContext, useMemo} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from 'reactstrap';
import { Formik, useFormik } from "formik";

function Datum(props){
    return(
        <div>{new Date(props.datum).toLocaleDateString()}</div>
    );
}
function NewDialog(props){
    const date = new Date().toISOString().slice(0, 10);
    const isEdit = props.data != null;
    
    const formik = useFormik({
        initialValues: {
            datum: date,
            hochtarif: isEdit ? props.data.hochtarif : 0,
            niedrigtarif: isEdit ? props.data.niedrigtarif : 0,
        },
        onSubmit: async (values) => {
            props.onStartReload();
            console.warn(JSON.stringify(values));
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
            };
           await fetch('strom', requestOptions);

           props.onReload();
        },
    });
    return(
        <Modal isOpen={props.isShown} animation="false" centered >
            <form onSubmit={formik.handleSubmit}>
            <ModalHeader>
                {isEdit ? 'Bearbeiten' : 'Neuer Eintrag'}
            </ModalHeader>
            <ModalBody>
                <div className="form-group">
                    <label htmlFor="datum">Datum</label>
                    <input
                    className="form-control"
                    id="datum"
                    name="datum"
                    type="date"
                    onChange={formik.handleChange}
                    value={formik.values.datum}/>
                </div>
                <div className="form-group">
                <label htmlFor="hochtarif">
                    Hochtarif
                </label>
                <input 
                    id="hochtarif"
                    className="form-control"
                    name="hochtarif"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.hochtarif}/>
                </div> 
                <div className="form-group">
                    <label htmlFor="niedrigtarif">Niedrigtarif</label>
                <input 
                    id="niedrigtarif"
                    className="form-control"
                    name="niedrigtarif"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.niedrigtarif}/>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={props.onClose}>Close</Button>
                <Button variant="primary" type="submit" onClick={props.onClose}>Save changes</Button>   
            </ModalFooter>
</form>
        </Modal>
    );
}

const AddEditButton = (props) => {
    const [isShown, setShown] = useState(false);
    
    const handleSubmit = () => {
        setShown(true);
    }
    
    const handleClose = () => {
        setShown(false);
    }
    
    const handleSave = () => {
        setShown(false);
    }
    
    const handleReload = () => {
        props.onReload();
    }
    
    const isEdit = props.data != null;
    
    return(
        <div>
         <NewDialog isShown={isShown} data={props.data} onClose={handleClose} onSave={handleSave} onReload={handleReload} onStartReload={props.onStartReload}/>
         <button className="btn btn-primary" onClick={handleSubmit}>{isEdit ? "Bearbeiten": "Neue Daten"}</button>
        </div>
    );
}
const FileIcon = (props) => {
    let style = {
        marginRight: '10px'
    }
    
    let handleClick = () => {
        alert("asdf")
    }
    
    return(
        <>
            {
                props.hasFoto &&
                <i style={style} onClick={handleClick} className="bi bi-file-image"></i>
            }
        </>
    )
}

const TableRow = (props) => {
    const handleClick = async () => {
        props.onStartReload();
        const x = "/strom?id=" + props.data.id;
        console.debug(x);
        await fetch(x, {method: 'DELETE'});
        props.onReload();
    }
    

    
    return (
        <tr>
            <td>      
                <div style={{ display: "flex" }}>
                <FileIcon hasFoto={props.data.fotoVorhanden} />
                <Datum datum={props.data.datum} /> 
                </div>
            </td>
            <td>{props.data.hochtarif}</td>
            <td>{props.data.niedrigtarif}</td>
            <td><AddEditButton data={props.data} /></td>
            <td><button className="btn btn-primary" onClick={handleClick} >Löschen</button></td>
        </tr>
    );
}

const Chart = (props) => {
    return (
        <div>
            <ResponsiveContainer width='100%' aspect={4.0/1}>
                <LineChart
                    data={props.daten}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="displayName" />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Line name="Hochtarif" type="monotone" dataKey="ht" stroke="#8884d8" />
                    <Line name="Niedrigtarif" type="monotone" dataKey="nt" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

const Year = (props) =>{
    return(
  
        <div>
            <h3>Durchschnittlicher Verbrauch auf Jahr</h3>
            <div>
                {props.daten && props.daten.map((d,i) =>
                    <YearRow key={d.displayName} value={d} />
                )}
            </div>
        </div>
    
    );
} 

const YearRow = (props) => {
    return(
        <div >
            <div> <h2>{props.value.displayName} </h2></div>
            <div>HT: {props.value.ht}kwh / 2500kwh ({2500 - props.value.ht}kwh)</div>
            <div>NT: {props.value.nt}kwv / 13000kwh ({13000 - props.value.nt}kwh)</div>
        </div>
    );
}

const Table = (props) => {
    const handleReload = () => {
        props.onReload();
    }
    
    return(
        <div>
            <table className='table'>
                <thead>
                <tr>
                    <th>Datum</th>
                    <th>HT</th>
                    <th>NT</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {props.daten && props.daten.map((d,i) =>
                    <TableRow key={d.id} data={d} onReload={handleReload}  onStartReload={props.onStartReload} />
                )}
                </tbody>
            </table>
        </div>
    );
}

const VergleichRow = (props) => {
    var ht2022 = props.data.stände[0].ht;
    var nt2022 = props.data.stände[0].nt;
    var ht2023 = props.data.stände.length >= 2 ? props.data.stände[1].ht : "Kein Wert";
    var nt2023 = props.data.stände.length >= 2 ? props.data.stände[1].nt : "Kein Wert";
    var ht2024 = props.data.stände.length >= 3 ? props.data.stände[2].ht : "Kein Wert";
    var nt2024 = props.data.stände.length >= 3 ? props.data.stände[2].nt : "Kein Wert";
    var ht2025 = props.data.stände.length >= 4 ? props.data.stände[3].ht : "Kein Wert";
    var nt2025 = props.data.stände.length >= 4 ? props.data.stände[3].nt : "Kein Wert";
    
    var diffHt2023 = ht2023 - ht2022;
    var diffNt2023 = nt2023 - nt2022;
    var diffHt2024 = ht2024 - ht2023;
    var diffNt2024 = nt2024 - nt2023;
    var diffHt2025 = ht2025 - ht2024;
    var diffNt2025 = nt2025 - nt2024;
    
    var proHt23 = -1 * (100-  ((ht2023 * 100) / ht2022))
    var proNt23 = -1 * (100- ((nt2023 * 100) / nt2022)) 
    var proHt24 = -1 * (100-  ((ht2024 * 100) / ht2023))
    var proNt24 = -1 * (100- ((nt2024 * 100) / nt2023)) 
    var proHt25 = -1 * (100-  ((ht2025 * 100) / ht2024))
    var proNt25 = -1 * (100- ((nt2025 * 100) / nt2024)) 
    
    var iconHt23 = isNaN(diffHt2023)? "" : diffHt2023 < 0 ? "bi-arrow-down-circle text-success" : "bi-arrow-up-circle text-danger"
    var iconNt23 = isNaN(diffNt2023) ? "" : diffNt2023 < 0 ? "bi-arrow-down-circle text-success" : "bi-arrow-up-circle text-danger"
    var iconHt24 = isNaN(diffHt2024)? "" : diffHt2024 < 0 ? "bi-arrow-down-circle text-success" : "bi-arrow-up-circle text-danger"
    var iconNt24 = isNaN(diffNt2024) ? "" : diffNt2024 < 0 ? "bi-arrow-down-circle text-success" : "bi-arrow-up-circle text-danger"
    var iconHt25 = isNaN(diffHt2025)? "" : diffHt2025 < 0 ? "bi-arrow-down-circle text-success" : "bi-arrow-up-circle text-danger"
    var iconNt25 = isNaN(diffNt2025) ? "" : diffNt2025 < 0 ? "bi-arrow-down-circle text-success" : "bi-arrow-up-circle text-danger"

    if (props.values === 'HT'){
        return (
            <tr>
                <td>{props.data.monat}</td>
                <td className="text-end">{ht2022} kw/h</td>
                <td className="text-end"> {isNaN(ht2023) ? "" : ht2023 + "kw/h"} <i className={'bi ' + iconHt23}> </i> {isNaN(diffHt2023) ? "Kein Wert" : Math.round(proHt23 * 10) / 10 + "%"}  </td>
                <td className="text-end"> {isNaN(ht2024) ? "" : ht2024 + "kw/h"} <i className={'bi ' + iconHt24}> </i> {isNaN(diffHt2024) ? "Kein Wert" : Math.round(proHt24 * 10) / 10 + "%"}  </td>
                <td className="text-end"> {isNaN(ht2025) ? "" : ht2025 + "kw/h"} <i className={'bi ' + iconHt25}> </i> {isNaN(diffHt2025) ? "Kein Wert" : Math.round(proHt25 * 10) / 10 + "%"}  </td>
            </tr>
        );
        
    }
    return (
        <tr>
            <td>{props.data.monat}</td>
            <td className="text-end">{nt2022} kw/h</td>
            <td className="text-end"> {isNaN(nt2023) ? "" : nt2023 + "kw/h"} <i className={'bi ' + iconNt23}> </i> {isNaN(diffNt2023) ? "Kein Wert" : Math.round(proNt23 * 10) / 10 + "%"}  </td>
            <td className="text-end"> {isNaN(nt2024) ? "" : nt2024 + "kw/h"} <i className={'bi ' + iconNt24}> </i> {isNaN(diffNt2024) ? "Kein Wert" : Math.round(proNt24 * 10) / 10 + "%"}  </td>
            <td className="text-end"> {isNaN(nt2025) ? "" : nt2025 + "kw/h"} <i className={'bi ' + iconNt25}> </i> {isNaN(diffNt2025) ? "Kein Wert" : Math.round(proNt25 * 10) / 10 + "%"}  </td>
        </tr>
    );

}

const Vergleich = (props) => {
    return (
        <div>
        <table  className='table'>
            <thead>
            <tr>
                <th>Monat</th>
                <th className="text-end">{props.values} - 2022</th>
                <th className="text-end">{props.values} - 2023</th>
                <th className="text-end">{props.values} - 2024</th>
                <th className="text-end">{props.values} - 2025</th>
            </tr>
            </thead>
            <tbody>
            {props.daten && props.daten.map((m,i) => 
                <VergleichRow key={m.id} values={props.values} data={m} />
            )}
            </tbody>
            </table>
        </div>
    )
}

const LoadingSpinner = () => {
    return(
        <div className="loading">
            <div className='uil-ring-css'>
                <div />
            </div>
        </div>
    );
}

export  function Overview() {
    const [daten, setDaten] = useState([]);
    const [chartDaten, setChartDaten] = useState([]);
    const [yearDaten, setYearDaten]= useState([]);
    const [gesamt, setGesamt]= useState({});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [vergleich, setVergleich] = useState([]);
    
    useEffect(async () => {
        loadData();
    }, [])
    
    const loadData = async () => {
        setLoading(true);
        const response = await fetch("strom");

        const da = await response.json();

        setDaten(da.all);
        setYearDaten(da.year);
        setGesamt(da.gesamt);
        setChartDaten(da.monthly);
        setLoading(false);
        setVergleich(da.vergleich);
    }
    
    
    const handleReload = async () => {
        await loadData();
    }
    
    const handleStartReload = () => {
        setLoading(true);
    }
    
    
    return (<>
            
                <AddEditButton onReload={handleReload} onStartReload={handleStartReload}/>
                <Chart daten={chartDaten} />
                <Year daten={yearDaten}/>
            <Vergleich values='HT' daten={vergleich} />
            <Vergleich values='NT' daten={vergleich} />
                <Table daten={daten} onReload={handleReload}  onStartReload={handleStartReload}/>
            {loading && <><LoadingSpinner /></>}

        </>
    );
}
